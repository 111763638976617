import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'

import newsAPI from '@Services/news-api'

export const fetchUseArticleQuery = async (urlkey: string) => {
  const url = `/${urlkey}?api_key=${process.env.ARTICLES_API_KEY}`

  const response = await newsAPI.get(url)
  const { data } = response

  return data.article
}

type SuccessResponse = Awaited<ReturnType<typeof fetchUseArticleQuery>>
type ErrorResponse = unknown

type ArticleQueryOptions = Omit<
  UseQueryOptions<SuccessResponse, ErrorResponse, SuccessResponse, string[]>,
  'queryKey' | 'queryFn'
>

export const useArticleQuery = (options?: ArticleQueryOptions) => {
  const params = useParams()
  const urlkey = params?.urlkey as string

  return useQuery({
    queryKey: ['article', urlkey],
    queryFn: () => fetchUseArticleQuery(urlkey),
    ...options
  })
}
