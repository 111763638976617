'use client'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'

import contentAPI from '@Services/contentAPI'

import { TheaterQueryOptions, theaterQueryOptions } from './utils'

export const theatersQueryKey = (cityId?: string, partnershipId?: string) => {
  const key = ['theaters']
  if (cityId && partnershipId) {
    key.push(cityId, partnershipId)
  }

  return key
}

const fetchTheaters = async (cityId?: string, partnershipId?: string) => {
  const theatersByCityUrl = `/v0/theaters/city/${cityId}/partnership/${partnershipId}`
  const allTheatersUrl = `/v0/theaters`

  const response = await contentAPI.get(
    cityId && partnershipId ? theatersByCityUrl : allTheatersUrl
  )

  const { data } = response
  return data
}

export const useTheaterQuery = (args?: TheaterQueryOptions) => {
  const params = useParams()
  const urlKey = args?.urlKey || (params?.urlkey as string)

  return useQuery(theaterQueryOptions({ urlKey, options: args?.options }))
}

export const useTheatersQuery = (
  cityId?: string,
  partnershipId?: string,
  options?: any
) =>
  useQuery({
    queryKey: theatersQueryKey(cityId, partnershipId),
    queryFn: () => fetchTheaters(cityId, partnershipId),
    ...options
  })
