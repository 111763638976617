import { useQuery } from '@tanstack/react-query'

import authAPI from '@Services/authAPI'

const fetchUserData = async (userId: string) => {
  const response = await authAPI.get(`/users/${userId}`)

  const { data } = response
  return data
}

export const useUserQuery = (userId: string, options?: any) =>
  useQuery({
    queryKey: ['userData', userId],
    queryFn: () => fetchUserData(userId),
    retry: false,
    ...options,
    refetchOnWindowFocus: false
  })
