import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  ACCESS_TOKEN,
  CLIENT_ORIGIN,
  DEFAULT_COOKIE_DOMAIN,
  DEFAULT_USER_DATA,
  KEEP_CONNECTED,
  REFRESH_TOKEN
} from '@Shared/constants/application'

import { User, UserCookie } from '@Schemas/application/user'

import authAPI from '@Services/authAPI'
import { cookies } from '@Services/cookies'

export const getUserData = (): User => {
  const hasCookieData = Boolean(cookies.get(ACCESS_TOKEN))
  const tokenData: UserCookie | User = hasCookieData
    ? JSON.parse(cookies.get(ACCESS_TOKEN))
    : DEFAULT_USER_DATA

  const userData: User = hasCookieData
    ? {
        id: (tokenData as UserCookie).user_id,
        token: (tokenData as UserCookie).access_token,
        name: (tokenData as UserCookie).user_first_name
      }
    : (tokenData as User)

  return userData
}

export const setUserCookies = (
  userData: User,
  token: { refreshToken: string; expiration: Date }
) => {
  const { expiration, refreshToken } = token
  cookies.set(
    ACCESS_TOKEN,
    JSON.stringify({
      user_id: userData?.id,
      access_token: userData?.token,
      user_first_name: userData?.name
    }),
    {
      domain: DEFAULT_COOKIE_DOMAIN(),
      path: '/',
      expires: expiration
    }
  )

  cookies.set(REFRESH_TOKEN, JSON.stringify(refreshToken), {
    domain: DEFAULT_COOKIE_DOMAIN(),
    path: '/',
    expires: expiration
  })
}

export const handleRefreshToken = async (
  service: AxiosInstance,
  error: AxiosError
) => {
  const { config, response } = error
  const originalRequest = config

  const access_token_error = response?.status === 401
  const refresh_token_error = response?.status === 403

  if (access_token_error || refresh_token_error) {
    const keep_me_connected_token = JSON.parse(
      cookies.get(KEEP_CONNECTED) ?? null
    )
    const userToken = cookies.get(ACCESS_TOKEN)
    const refresh_token = JSON.parse(cookies.get(REFRESH_TOKEN))

    if (access_token_error && !keep_me_connected_token) {
      return Promise.reject(error)
    }

    if (userToken) {
      cookies.destroy(ACCESS_TOKEN)
    }

    if (!refresh_token) {
      return Promise.reject(error)
    }

    try {
      const payload = {
        grant_type: 'refresh_token',
        client_origin: CLIENT_ORIGIN,
        refresh_token,
        keep_me_connected_token
      }

      const response = await authAPI.post(
        '/token',
        new URLSearchParams(payload)
      )
      const { data } = response

      if (response.status !== 200) {
        throw 'Error on refresh token'
      }

      const expiration = new Date(data['.expires'])
      expiration.setFullYear(expiration.getFullYear() + 1)

      const userData = {
        id: data.user_id,
        name: data.user_first_name,
        token: data.access_token
      }

      setUserCookies(userData, {
        refreshToken: data.refresh_token,
        expiration
      })

      if (originalRequest?.headers?.Authorization) {
        originalRequest.headers.Authorization = `Bearer ${userData.token}`
      }

      return await service(originalRequest as AxiosRequestConfig)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  return Promise.reject(error)
}
