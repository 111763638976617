import { viewEventItems, viewEventName } from '@Schemas/application/tracking'

import tracking from '@Services/tracking'

const eventCategoryMapping = {
  movie: 'Showtimes by movie',
  movie_list: 'Movies List',
  home: 'Home Page',
  theater: 'Showtimes by Cinema',
  theater_list: 'Theaters List',
  fanshop: 'FanShop',
  news: 'News',
  article: 'Article'
}

export const eventCategory = (pageName: string) =>
  eventCategoryMapping[pageName.toLowerCase()] ?? ''

export const getCarouselViewItemList = (data) => {
  const arrayData = () =>
    data?.[0]?.corporation
      ? data
      : data.pages
        ? data?.pages?.[0]?.articles
        : data?.events?.length !== 0
          ? data.events
          : data.items

  const getCategory = () => {
    if (data.type) {
      return data.type.toLowerCase() === 'filmes'
        ? data.carouselSlug.toLowerCase() === 'destaque'
          ? 'Highlights'
          : 'Ticket'
        : 'Event'
    }

    return data.pages ? 'Article' : 'Favorite Theaters'
  }

  const defaultData = (item, index) => {
    return {
      item_name: item.title || item.name,
      item_id: item.id || item.name,
      item_brand: item.distributor || item.corporation || '',
      item_category: getCategory(),
      item_list_name:
        data.name || (data.pages ? 'Noticias' : 'Cinemas favoritos'),
      index: index
    }
  }

  const promotionData = (item) => {
    return {
      promotion_id: item.name,
      promotion_name: item.name,
      creative_slot: 'Home List',
      location_id: 'Home'
    }
  }

  return arrayData()?.reduce((acc, item) => {
    acc?.push(
      data.type === 'promocoes'
        ? promotionData(item)
        : defaultData(item, acc.length)
    )
    return acc
  }, [])
}

export const viewEvent = (eventName: viewEventName, data: viewEventItems) => {
  tracking.event({
    event: eventName,
    ecommerce: {
      items: data
    }
  })
}
