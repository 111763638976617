import { useQuery } from '@tanstack/react-query'

import {
  CLIENT_ORIGIN,
  KEEP_CONNECTED,
  REFRESH_TOKEN
} from '@Shared/constants/application'

import authAPI from '@Services/authAPI'
import { cookies } from '@Services/cookies'

const fetchRefreshToken = async () => {
  const keep_me_connected_token = JSON.parse(
    cookies.get(KEEP_CONNECTED) ?? null
  )
  const refresh_token = JSON.parse(cookies.get(REFRESH_TOKEN) ?? null)

  const payload = {
    grant_type: 'refresh_token',
    client_origin: CLIENT_ORIGIN,
    refresh_token,
    keep_me_connected_token
  }

  const response = await authAPI.post('/token', new URLSearchParams(payload))

  const { data } = response
  return data
}

export const useRefreshTokenQuery = (options?: any) =>
  useQuery<any>({
    queryKey: ['userToken'],
    queryFn: () => fetchRefreshToken(),
    retry: false,
    gcTime: 0,
    ...options
  })
