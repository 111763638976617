import type { PageView, TrackEvent } from 'src/schemas/application/tracking'

type Tracking = {
  event: (data: TrackEvent) => void
  pageView: (data: PageView) => void
}

const dataLayerReset = {
  event: 'dataLayer reset',
  ecommerce: null,
  spectacle: null,
  place: null,
  page: null,
  article: null,
  user: null
}

const tracking: Tracking = {
  event: function (data) {
    const events =
      typeof window !== 'undefined'
        ? window.dataLayerGTM
        : new Array<TrackEvent>()

    events?.push(data)
  },
  pageView: function (data) {
    this.event(dataLayerReset)
    this.event(data)
  }
}

export default tracking
