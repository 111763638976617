import {
  QueryFunctionContext,
  UseQueryOptions,
  queryOptions
} from '@tanstack/react-query'

import contentAPI from '@Services/contentAPI'

type TheaterQueryKey = ['theater', { urlKey: string }]
type ErrorResponse = Error
type TheaterSuccessResponse = Awaited<ReturnType<typeof getTheater>>

export type TheaterQueryOptions = {
  urlKey: string
  options?: Omit<
    UseQueryOptions<
      TheaterSuccessResponse,
      ErrorResponse,
      TheaterSuccessResponse,
      TheaterQueryKey
    >,
    'queryKey' | 'queryFn'
  >
}

export const getTheater = async ({
  queryKey
}: QueryFunctionContext<TheaterQueryKey>) => {
  const [_key, { urlKey }] = queryKey
  const url = `/v0/theaters/url-key/${urlKey}/partnership/home`

  const response = await contentAPI.get(url)
  const { data } = response
  return data
}

export const theaterQueryOptions = ({
  urlKey,
  options
}: TheaterQueryOptions) => {
  return queryOptions({
    queryKey: ['theater', { urlKey }],
    queryFn: getTheater,
    ...options
  })
}
